import $ from 'jquery'

class CheckStatus {
  constructor (options = {}) {
    this.getStatusPath = options.getStatusPath || window.location.href
    this.redirectToPath = options.redirectToPath
  }

  poll () {
    $.ajax({
      cache: false,
      context: this,
      dataType: 'json',
      method: 'GET',
      url: this.getStatusPath
    })
    .done( function(response) {
      if(response.done) {
        window.location = this.redirectToPath
      } else {
        setTimeout(this.poll.bind(this), 2000)
      }
    })
    .fail( function(error) {
      // Ajax errors are handled globally
    })
  }
}

export default CheckStatus
